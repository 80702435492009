<div class="vh-100 d-flex flex-column justify-content-center fondoPrincipal">
  <div class="text-center w-full align-items-center text-center item-center">
    <div class="bg-gray-200 bg-opacity-75 max-w-90 mx-auto py-3 rounded-lg font-bold">
      <h1>Empresas</h1>
      <div class="grid grid-cols-2 px-3">
        <div class="flex justify-end pr-10 items-center"> <!-- Para alinear a la derecha -->
          <img src="assets/icons/icon-128x128.png" style="max-height: 130px; max-width: 200px;"/>
        </div>
        <div class="flex justify-start items-center"> <!-- Para alinear a la izquierda -->
          <img src="assets/images/newAlicopsa.png" style="max-height: 100px; max-width: 200px;" />
        </div>
      </div>
      
      <h2>Presentan el curso:</h2>
    </div>
    <div class="py-20 bg-orange-600 text-white text-2xl font-bold w-full">
      <h1>
        BUENAS PRÁCTICAS DE MANUFACTURAS PARA MANIPULADORAS DE ALIMENTOS PAE -
        PAP
      </h1>
    </div>
    <div>
      <div
        class="btn btn-primary m-2 p-4"
        (click)="loginBTN()"
        *ngIf="!loginStatus"
      >
        INICIAR SESIÓN
      </div>

      <div
        class="btn btn-primary m-2 p-4"
        (click)="openBTN()"
        *ngIf="loginStatus"
      >
        ENTRAR
      </div>
      
    </div>
    <!-- <div
    class="btn btn-primary m-2 p-4"
    (click)="buscaActualizaciones()"
  >
    BUSCAR ACTUALIZACIONES
  </div> -->
  </div>
  <!-- <div *ngIf="isRunningInBrowser()">
    <button (click)="installPWA()">Instalar la PWA</button>
  </div> -->

</div>
