import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import Swal from "sweetalert2";

//Inicio Importa para variable de avanzar pregunta
import { Router } from "@angular/router";
import { TrackingService } from "src/app/services/tracking/tracking.service";
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
//Fin Importa para variable de avanzar pregunta

@Component({
  selector: "app-modulo-auto-instruccion",
  templateUrl: "./modulo-auto-instruccion.component.html",
  styleUrls: ["./modulo-auto-instruccion.component.scss"],
})
export class ModuloAutoInstruccionComponent implements OnInit {
  //Inicio Variable para avanzar de pagina
  pagina: number;
  link: any;
  modulo: any = 0;
  numberModulo: number = 0;
  //Fin Variable para avanzar de pagina

  constructor(
    //Inicio declaracion de import para uno de avanzar pregunta
    private trackingService: TrackingService,
    private router: Router,
    private cryptoServ: CryptoservService //Fin declaracion de import para uno de avanzar pregunta
  ) {}

  ngOnInit(): void {}

  async updateInfoBd(rut: string, modulo: number, pagina: number) {
    await this.trackingService.setAllTracking(rut, modulo, pagina);
  }

  finModulo() {
    var temp = localStorage.getItem("modulo");
    var lastModule = "0";
    if (temp != null && temp != undefined && temp != "") {
      lastModule = this.cryptoServ.decrypted(temp);
    }
    //var lastModule = localStorage.getItem('modulo');
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    //var rutUser = localStorage.getItem('rut');
    if (parseInt(lastModule) < this.numberModulo + 1) {
      this.numberModulo = this.numberModulo + 1;
      const encryptModulo = this.cryptoServ.encrypted(this.numberModulo);
      localStorage.setItem("modulo", encryptModulo);
      //localStorage.setItem('modulo', JSON.stringify(this.numberModulo + 1));
      this.pagina = 0;
      var encrypt = this.cryptoServ.encrypted(0);
      localStorage.setItem("pagina", encrypt);
      //localStorage.setItem('pagina', JSON.stringify(1));

      this.updateInfoBd(rutUser, this.numberModulo, this.pagina);
    }
    this.router.navigate(["/curso"]);
  }

  // ---------------------------------------------
  // FIN de informacion para avanzar de pagina
}
