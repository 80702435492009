import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RutService } from 'rut-chileno';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscriber } from 'rxjs';
import { CryptoservService } from 'src/app/services/cryposerv/cryptoserv.service';
import Swal from 'sweetalert2';


@Injectable({ providedIn: 'root' })
export class LoginService {
  authUsers: any = {};
  cargada = false;
  userRut = '';
  user: any = {
    rut: '',
    name: ''
  };
  observe: Subscriber<any>;

  constructor(
    private http: HttpClient,
    private rutService: RutService,
    private cryptoServ: CryptoservService,
    private bdFirebase: AngularFirestore,
  ) {
    http.get('../../../assets/json/data.json').subscribe(res => {
      this.authUsers = res;
      this.cargada = true;
    });
  }

  public getUser(): any {
    if (localStorage.getItem('usuario')) {
      return localStorage.getItem('usuario');
    } else {
      return false;
    }
  }

  public getListAuthUsers(): any {
    return this.authUsers.users;
  }

  public getLoginStatus(): any {
    if (this.getUser()) {
      return true;
    } else {
      return false;
    }
  }

  public setAuthUsers(user: any): void {
    this.user.rut = user.RUT;
    this.user.name = user.NAME;
    this.user.perfil = user.PERFIL;
    this.user.tipoUsuario = user.TIPOUSUARIO;
  }

  public setAuthUserLocalStorage(data: any): void {
    var encrypObject = data;
    encrypObject.rut = this.cryptoServ.encrypted(encrypObject.rut);
    encrypObject.name = this.cryptoServ.encrypted(encrypObject.name);
    encrypObject.perfil = this.cryptoServ.encrypted(encrypObject.perfil);
    encrypObject.tipoUsuario = this.cryptoServ.encrypted(encrypObject.tipoUsuario);
    localStorage.setItem('usuario', JSON.stringify(encrypObject));
  }

  async validateRut(rut: string) {
    rut = rut.toUpperCase();
    try {
      let userFind = await this.getListAuthUsers().find(res => res.RUT == rut);
      if (userFind) {
        this.setAuthUsers(userFind);
        this.setAuthUserLocalStorage(this.user);
        return true;
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Usuario no registrado',
          text: 'Favor revisar rut o realizar clic en "Solicitar Ayuda" si el rut es correcto',
          confirmButtonText: `ok`,
          willClose: () => {
            localStorage.clear();
            window.location.reload();
          }
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            localStorage.clear();
            window.location.reload();
          }
        });
        return false;
      }
    } catch (error) {
      console.log("se obtuvo un error al obtener el rut");
      console.log(error);
      Swal.fire({
        icon: 'warning',
        title: 'Usuario no registrado',
        text: 'Favor revisar rut o realizar clic en "Solicitar Ayuda" si el rut es correcto',
        confirmButtonText: `ok`,
        willClose: () => {
          localStorage.clear();
          window.location.reload();
        }
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          localStorage.clear();
          window.location.reload();
        }
      });
      return false;
    }
  }

  async validateVersion(versionActual: number){
    var data = null;
    var rest = await this.bdFirebase.collection('version').doc('version').ref.get().then(function (doc): any {
      console.log("Se validara documento");
      console.log(doc);
      data = doc.data();
      console.log(data);
      if(versionActual>=data.numVersion){
        console.log("Version es mayor o igual")
      }else{
        console.log("Version es menor,procede a descargar")
      }

      
    }).catch(function (error) {
      let auxError = {
        "error": true,
        "titulo": "Error en uso de plataforma",
        "msj": "Error no conocido: "+error.code+", Favor comunicarse con soporte"
      }    
      return auxError; 
    })

  }


  async validateRutFireBase(rut: string) {
    try {
      rut = rut.toLowerCase();
      var data = null;
      var rest = await this.bdFirebase.collection('usuario').doc(rut).ref.get().then(function (doc): any {
        console.log("Se validara documento");
        console.log(doc);
        if (doc.exists) {
          console.log("Si existe documento");
          data = doc.data();
          console.log(data);
          var auxInfo = {
            "error": false,
            "msj": "",
            "RUT": rut,
            "NAME": data.Nombre,
            "PERFIL": data.Perfil,
            "TIPOUSUARIO": data.tipoUsuario
          }
          return auxInfo;
        } else {
          console.log("No existe Documento");
          data = null;
          let auxError = {
            "error": true,
            "titulo": "Usuario no registrado",
            "msj": "Favor de revisar el RUT o comunicarte con soporte",
            "RUT": "'",
            "NAME": "",
            "PERFIL": "",
            "TIPOUSUARIO": ""
          }
          return auxError;
        }
      }).catch(function (error) {
        console.log(error.code);
        console.log("HOLAAAAA");
        let auxError = {};
        if (error.code === 'unavailable') {
          auxError = {
            "error": true,
            "titulo": "No existe conexión con el servicio",
            "msj": "Por favor, revisa tu conexión a internet. <br> Si el error persiste, comunícate con soporte técnico",
            "RUT": "'",
            "NAME": "",
            "PERFIL": "",
            "TIPOUSUARIO": ""
          }
        } else {
          auxError = {
            "error": true,
            "titulo": "Error en uso de plataforma",
            "msj": "Error no conocido: "+error.code+", Favor comunicarse con soporte",
            "RUT": "'",
            "NAME": "",
            "PERFIL": "",
            "TIPOUSUARIO": ""
          }
        }
        return auxError;
      });
      if(!rest.error){
        this.setAuthUsers(rest);
        this.setAuthUserLocalStorage(this.user);
        return {
          "error": false
        };
      } else {
        return {
          "error": true,
          "titulo": rest.titulo,
          "msj": rest.msj,
        };
      }
    } catch (error) {
      let auxError = {
        "error": true,
        "titulo": "Error en uso de plataforma",
        "msj": "Error no conocido: "+error.code+", Favor comunicarse con soporte"
      }
    return auxError;
    }
  }
}
