<div class="vh-100 p-3 d-flex flex-column justify-content-center align-items-center">
    <div class="text-center">
        <br>
        <h1>Empresa Alicopsa </h1> 
        <br>
        <br>
        <h4>Este boton borrar toda la información presente en el celular, para que esta pueda ser instalada nuevamente</h4>
        <div align="center"><img src="assets/images/logoAlicopsa.png"></div>
    </div>
    <div class="row">
        <div class="btn btn-primary m-2 p-4" (click)='borrarInfo()'>Borrar Cache</div>
    </div>
</div>