import { Component, OnInit } from '@angular/core';
import { CryptoservService } from './services/cryposerv/cryptoserv.service';
import { PWAService } from './services/pwa/pwa.service';
import { TrackingService } from './services/tracking/tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  public validadorCacheError: boolean;

  constructor(
    private pwaservice: PWAService,
    private cryptoServ: CryptoservService,
    private trackingService: TrackingService
    // private loginService:
    ) { }

  ngOnInit(): void {
    console.log("inicie el ngInit")
    this.pwaservice.listen();
    //this.limpiarCacheErrores();
    this.validadorCacheError = false;
  }

  limpiarCacheErrores(): void {
    let modulo = 0;
    let rut = "";
    if(localStorage.getItem('modulo') != null){
      modulo = Number(this.cryptoServ.decrypted(localStorage.getItem('modulo')));
    } 
    if(localStorage.getItem('rut') != null){
      rut = this.cryptoServ.decrypted(localStorage.getItem('rut'));
    } 
    if ( modulo > 5 ) {
      this.validadorCacheError = true;
      this.trackingService.getAllTracking(rut).then( data => {
        if (data.modulo > 4) {
          this.trackingService.setAllTracking(rut, 0, 1);
        }
      });
      localStorage.clear();
    }
  }
}
