import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router
    ){}
  canActivate(): boolean {
    if (!this.loginService.getLoginStatus()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
