<div class="container-pregunta">
  <div class="block-preguntas flex flex-col items-center pt-1 bg-white rounded-lg shadow-lg w-full">
    <h1 class="text-lg font-bold py-1" *ngIf="titulo != ''">{{ titulo }}</h1>
    <h3 class="text-justify text-sm px-4" [innerHTML]="descripcion"></h3>
    <div>
      <div class="w-full p-3">
        <div *ngFor="let option of opciones; let i = index" class="flex list-group-item text-xs">
          <label for="option{{ i }}"> {{ getLetter(i) + ' - '+ option }}</label>
        </div>
      </div>
      <div class="text-center text-sm">
        Elija la alternativa correcta
      </div>
      <div class="w-full p-3">
        <div 
        *ngFor="let choice of choices; let j = index" 
        class="flex list-group-item text-xs w-full"
        >
          <button
          [disabled]="isRespondida"
            type="button"
            id="choice{{ j }}"
            [value]="choice"
            class="mr-2 w-full text-left min-h-10"
            [ngClass]="validRespondida(j) ? 'bg-green-200' : ''"
            (click)="opcSeleccionada(j)"
          >
          <label for="choice{{ j }}" class="text-left"> 
            {{(j+1) + ') ' + choice }}
          </label>
        </button>
        </div>
      </div>
    </div>
  </div>
</div>
