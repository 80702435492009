<div class="container-pregunta">
  <div
    class="block-preguntas flex flex-col items-center pt-1 bg-white rounded-lg shadow-lg w-full "
  >
    <h1 class="text-lg font-bold py-1" *ngIf="titulo != ''">{{ titulo }}</h1>
    <div class="text-justify text-sm px-4" [innerHTML]="descripcion"></div>
    <form>
      <div class="w-full p-3">
        <div class="text-justify text-sm px-2" [innerHTML]="inputHtml"></div>
      </div>
      <div class="text-center text-sm">Elija la alternativa correcta</div>
      <div class="w-full p-3">
        <div
          *ngFor="let opcion of opciones; let i = index"
          class="flex list-group-item text-sm md:text-lg w-full"
          [disabled]="isRespondida"
        >
          <button
            [disabled]="isRespondida"
            type="button"
            id="choice{{ i }}"
            [value]="opcion"
            class="mr-2 w-full text-left min-h-10"
            [ngClass]="validRespondida(i) ? 'bg-green-200' : ''"
            (click)="opcSeleccionada(i)"
          >
            <label for="choice{{ i }}" class="text-left">
              {{ i + 1 + ") " + opcion }}</label
            >
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
