<div
  class="main-footer d-flex flex-row align-items-center justify-content-between p-4"
>
  <button *ngIf="!validFirstPag()" mat-icon-button (click)="prevPageClicked()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <div>
    {{ modulo }} - {{ page }}
  </div>
  <button *ngIf="validLastPag()" mat-icon-button (click)="nextPageClicked()">
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
