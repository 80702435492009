<app-toolbar></app-toolbar>
<div class="container">
  <div class="pdf-container" *ngIf="isPaginaPdf()">
    <pdf-viewer
      [src]="newPdf"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page"
      [original-size]="false"
      class="pdf-viewer"
    ></pdf-viewer>
  </div>
  

  <div *ngIf="!isPaginaPdf()">
    <div *ngIf="validShowPregunta(1)">
      <app-simple-question
        [opciones]="opcPreguntaUno"
        [correctAnswer]="correctaPregUno"
        [titulo]="tituloPregUno"
        [descripcion]="descripcionPregUno"
        [isRespondida]="validRespondida(1)"
        (sendRespuesta)="validarSimpleQuestion($event,1)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(2)">
      <app-simple-question
        [opciones]="opcPreguntaDos"
        [correctAnswer]="correctaPregDos"
        [titulo]="tituloPregDos"
        [descripcion]="descripcionPregDos"
        [isRespondida]="validRespondida(2)"
        (sendRespuesta)="validarSimpleQuestion($event,2)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(3)">
      <app-simple-question
        [opciones]="opcPreguntaTres"
        [correctAnswer]="correctaPregTres"
        [titulo]="tituloPregTres"
        [descripcion]="descripcionPregTres"
        [isRespondida]="validRespondida(3)"
        (sendRespuesta)="validarSimpleQuestion($event,3)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(4)">
      <app-simple-question
        [opciones]="opcPreguntaCuatro"
        [correctAnswer]="correctaPregCuatro"
        [titulo]="tituloPregCuatro"
        [descripcion]="descripcionPregCuatro"
        [isRespondida]="validRespondida(4)"
        (sendRespuesta)="validarSimpleQuestion($event,4)"
      ></app-simple-question>
    </div>

    <div class="p-4 justify-center item-center" *ngIf="validShowPregunta(5)">
      <h1>🎉 ¡FELICIDADES HAZ FINALIZADO EL MODULO! 🎉</h1>
      <img class="w-full p-4" src="../../../../assets/images/fin-modulo.jpg" />
      <div class="w-100 px-4 py-6">
        <button
          class="btn bg-orange-500 btn-lg w-100 p-2 text-white text-lg font-bold"
          (click)="finModulo()"
        >
          Salir
        </button>
      </div>
    </div>
  </div>
</div>
<app-paginacion
  [page]="page"
  [totalPages]="totalPages"
  [cantPreg]="cantPreg"
  (prevPage)="prevPage()"
  (nextPage)="nextPage()"
></app-paginacion>
