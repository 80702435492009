import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { LoginService } from 'src/app/services/login/login.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageZoomDialogComponent } from '../imageZoomDialog/image-zoom-dialog.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  loginStatus: any;
  deferredPrompt: any;
  showInstallButton = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private appComponent: AppComponent,
    public dialog: MatDialog
  ) {
    this.loginStatus = this.loginService.getLoginStatus();
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(e: Event) {
    console.log('beforeinstallprompt event triggered');
    e.preventDefault();
    this.deferredPrompt = e;
    this.showInstallButton = true; // Muestra el botón de instalación
  }

  ngOnInit(): void {
    this.appComponent.limpiarCacheErrores();
    const infoAux = this.isInstalled();
    console.log(infoAux);
    this.showInstallButton = this.isRunningInBrowser(); // Verifica si debe mostrar el botón de instalación
  }

  loginBTN(): void {
    this.router.navigate(['login']);
  }

  openBTN(): void {
    this.appComponent.limpiarCacheErrores();
    this.router.navigate(['curso']);
  }

  isInstalled(): any {
    const isInStandaloneMode =
      ('standalone' in window.navigator && window.navigator['standalone']) ||
      window.matchMedia('(display-mode: standalone)').matches;
    return isInStandaloneMode;
  }

  installPWA() {
    if (this.deferredPrompt) {
      console.log("a")
      this.deferredPrompt.prompt();
      console.log("a1")
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        console.log("a2")
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        console.log("a3")
        this.deferredPrompt = null;
        this.showInstallButton = false; // Oculta el botón después de la elección
      });
    }
  }

  isRunningInBrowser(): boolean {
    return !this.isInstalled();
  }

  async buscaActualizaciones(){
    console.log("hola")
    await  this.loginService.validateVersion(0);
  }


  openImageZoom(imageUrl: string): void {
    this.dialog.open(ImageZoomDialogComponent, {
      data: { imageUrl }
    });
  }



}
