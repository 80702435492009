import { Component, OnInit, ChangeDetectorRef, Renderer2, AfterViewInit } from "@angular/core";
import Swal from "sweetalert2";

//Inicio Importa para variable de avanzar pregunta
import { Router } from "@angular/router";
import { TrackingService } from "src/app/services/tracking/tracking.service";
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
import { PDFDocumentProxy } from "pdfjs-dist";
//Fin Importa para variable de avanzar pregunta

@Component({
  selector: "app-modulo4",
  templateUrl: "./modulo4.component.html",
  styleUrls: ["./modulo4.component.scss"],
})
export class Modulo4Component implements OnInit, AfterViewInit {
  //Inicio Variable para avanzar de pagina
  pagina: number;
  numberModulo: number = 4;
  //Fin Variable para avanzar de pagina
  page: number=1;

  constructor(
    //Inicio declaracion de import para uno de avanzar pregunta
    private trackingService: TrackingService,
    private router: Router,
    private renderer: Renderer2,
    private cryptoServ: CryptoservService,
    private _changeDetectorRef: ChangeDetectorRef //Fin declaracion de import para uno de avanzar pregunta
  ) {}
  preguntas = [];
  respuesta = [];
  pathPdf = "../../../../assets/PDF/";

  pdfModulo4 = this.pathPdf + "modulo4_full.pdf";
  
  img1 = this.pathPdf + "mod4Imagen.jpeg";
  img2 = this.pathPdf + "mod4Imagen2.jpg";
  img3 = this.pathPdf + "mod4Imagen3.jpg";

  totalPages: number = 9999;
  cantPreg = 5;

  pdfActive: boolean[];
  imgActive: boolean[];
  preguntaActive: boolean[];

  //pregunta 1
  titulo1: string = "Evaluación del Módulo 4 - Pregunta 1";
  descripcion1: string =
    "La definición a continuación corresponde al término: <br> Cocción muy corta en abundante agua hirviendo, para conservar color y mejor textura de las verduras.  Se sumergen los alimentos un par de minutos, se cuelan y se dejan enfriar inmediatamente, para conservar sus texturas y colores";
  opciones1: string[] = [
    "Blanquear o escaldar",
    "Espumar",
    "Sellar",
    "Escurrir",
  ];
  correcta1: string = "Blanquear o escaldar";

  //pregunta 2
  titulo2: string = "Evaluación del Módulo 4 - Pregunta 2";
  descripcion2: string =
    "Corte que se utiliza para dar forma de cubos entre 1 a 2 cm por lado. Esta definición corresponde al tipo de corte:";
  opciones2: string[] = [
    "Brunoise",
    "Bastones",
    "Parmentier",
    "Chiffonade",
    "Vichy",
  ];
  correcta2: string = "Parmentier";

  //pregunta 3
  titulo3: string = "Evaluación del Módulo 4 - Pregunta 3";
  descripcion3: string =
    "La técnica culinaria tiene operaciones las que se dividen en preliminares, fundamentales  y definitivas <br> La Operación Preliminar es  el proceso de :";
  opciones3: string[] = [
    "Limpieza, descongelado y cortes de materia prima",
    "Limpieza, sanitizado, descongelado y cortes de materia prima"
  ];
  correcta3: string = "Limpieza, sanitizado, descongelado y cortes de materia prima";

  //Pregunta 5
  titulo4 = "Evaluación del Módulo 4";
  descripcion4 = "Responda las preguntas y elija  V o F";
  preguntas4: any[] = [
    {
      text: "En el cuaderno de cálculo de ingredientes, usted debe: Realizar el cálculo de todos los ingredientes que corresponda a las minutas del día de acuerdo al manual y número de raciones por servicio y además indicar un check cuando usted haya lavado y sanitizado de frutas, verduras frescas y huevo  ",
      value: true,
    },
    {
      text: "En Jardines, para porcionar la leche reconstituida en los jarros de desayuno, se debe controlar con un termómetro en  que la temperatura esté entre 39,0ºc a 37,0 ºc",
      value: true,
    },
    {
      text: "La solución sanitizante para frutas, verduras es 1 cucharadita de 5 ml  de cloro en 10 litros de agua fría por 5 minutos",
      value: true,
    },
  ];

  ngOnInit(): void {
    var paginaLink = +window.location.href.split("#")[1];
    var forPagina = "pagina" + paginaLink;
    console.log("paginaLink: " + paginaLink);
    var NumPag: number = paginaLink;
    console.log("num pagina: " + NumPag);
    this.validarAvancePagina(NumPag);
    //document.getElementById(forPagina).hidden = false;
  }

  ngAfterViewInit() {
    const pdfViewer = document.querySelector('pdf-viewer');

    if (pdfViewer) {
      const canvasWrapper = pdfViewer.querySelector('.canvasWrapper');
      const textLayer = pdfViewer.querySelector('.textLayer');

      if (canvasWrapper) {
        this.renderer.setStyle(canvasWrapper, 'overflow', 'auto');
      }

      if (textLayer) {
        this.renderer.setStyle(textLayer, 'position', 'relative');
      }

      // Asegúrate de que el visor del PDF se centra verticalmente
      this.renderer.setStyle(pdfViewer, 'display', 'flex');
      this.renderer.setStyle(pdfViewer, 'flex-direction', 'column');
      this.renderer.setStyle(pdfViewer, 'justify-content', 'center');
      this.renderer.setStyle(pdfViewer, 'align-items', 'center');
    }
  }

  isPaginaPdf() {
    if (this.page <= this.totalPages) {
      return true;
    }
    return false;
  }

  isImagenActiva(pos) {
    if(this.page==this.totalPages+pos+1){
      return true
    }
    return false;
  }

  isPreguntaActiva() {
    if(this.page>this.totalPages+3){
      return true
    }
    return false;
  }


  validarAvancePagina(pagina) {
    switch (pagina) {
      case 2:
        this.page = 2;
        break;
      case 3:
        this.page = 12;
        break;
      case 4:
        this.page = 16;
        break;
      case 5:
        this.page = 18;
        break;
      case 6:
        this.page = 19;
        break;
      case 7:
        this.page = 21;
        break;
      case 8:
        this.page = 22;
        break;
      case 9:
        this.page = 23;
        break;
      case 10:
        this.page = 23;
        break;
      case 11:
        this.page = 25;
        break;
      case 12:
        this.page = 26;
        break;
      case 13:
        this.page = 27;
        break;
      case 14:
        this.page = 28;
        break;
      case 15:
          this.page = 29;
          break;
      case 16:
          this.page = 34;
          break;
      case 17:
          this.page = 36;
          break;
      case 18:
          this.page = 37;
          break;
      case 19:
          this.page = 38;
          break;
    }
  }

  validarQuestion(data: any, posicion: number) {
    if (data) {
      //localStorage.setItem("m1-p1", "true");
      const pregunta = this.numberModulo.toString() + "-" + posicion.toString();
      localStorage.setItem(pregunta, "true");
      this.page++;
      this.validSavePag();
      Swal.fire({
        icon: "success",
        title: "¡Super Bien!",
        text: "¡Tu respuesta esta correcta! 🥳",
        confirmButtonText: `¡Avanzar a la siguiente pregunta!`,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "¡No es correcta tu respuesta! 😔  Favor seguir intentando",
        confirmButtonText: `Volver a responder`,
      });
    }
  }

  validShowPregunta(pos) {
    if (this.page - this.totalPages-2 == pos) {
      return true;
    }
    return false;
  }

  validRespondida(number) {
    const pregunta = this.numberModulo.toString() + "-" + number.toString();
    console.log(pregunta);
    const infoEncryp = localStorage.getItem(pregunta);
    console.log(infoEncryp);
    if (infoEncryp == null || infoEncryp == undefined || infoEncryp == "") {
      return false;
    }
    return true;
  }

  async updateInfoBd(rut: string, modulo: number, pagina: number) {
    await this.trackingService.setAllTracking(rut, modulo, pagina);
  }

  //sath revisar
  validSavePag() {
    var temp = localStorage.getItem("modulo");
    var lastModule = this.cryptoServ.decrypted(temp);
    // var lastModule = localStorage.getItem('modulo');
    var temp = localStorage.getItem("pagina");
    var lastPagina = this.cryptoServ.decrypted(temp);
    // var lastPagina = localStorage.getItem('pagina');
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    // var rutUser = localStorage.getItem('rut');
    if (
      this.numberModulo == parseInt(lastModule) &&
      this.page > parseInt(lastPagina)
    ) {
      var encrypt = this.cryptoServ.encrypted(this.page);
      localStorage.setItem("pagina", encrypt);
      // localStorage.setItem('pagina', JSON.stringify(this.pagina));
      this.updateInfoBd(rutUser, this.numberModulo, this.page);
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this._changeDetectorRef.markForCheck();
  }

  // Método que se llama cuando se renderiza una página
  onPageRendered(event: any) {
    // if (this.page === this.totalPages) {
    //   console.log("Última página del PDF mostrada");
    //   // Aquí puedes ejecutar cualquier lógica adicional que necesites
    // }
  }

  // Método para ir a la página anterior
  prevPage() {
    if (this.page > 1) {
      this.page--;
    }
  }

  nextPage() {
    this.page++;
    // var encrypt = this.cryptoServ.encrypted(this.page);
    // localStorage.setItem("pagina", encrypt);
    //this.validSavePag();
  }

  clean() {
    this.pdfActive = [];
    this.imgActive = [];
    this.preguntaActive = [];
    for (let index = 0; index < 12; index++) {
      this.pdfActive.push(false);
    }
    for (let index = 0; index < 7; index++) {
      this.imgActive.push(false);
    }
    for (let index = 0; index < 7; index++) {
      this.preguntaActive.push(false);
    }
  }



  // isPaginaPdf() {
  //   if (this.page <= this.totalPages) {
  //     return true;
  //   }
  //   return false;
  // }

  finModulo() {
    const tempModulo = localStorage.getItem("modulo");
    const lastModule = this.cryptoServ.decrypted(tempModulo);
    const tempRut = localStorage.getItem("rut");
    const rutUser = this.cryptoServ.decrypted(tempRut);
    if (parseInt(lastModule) < this.numberModulo + 1) {
      const newPage = 0;
      const numberModulo = this.numberModulo + 1;
      const encryptModulo = this.cryptoServ.encrypted(numberModulo);
      localStorage.setItem("modulo", encryptModulo);
      const encryptPagina = this.cryptoServ.encrypted(0);
      localStorage.setItem("pagina", encryptPagina);
      this.updateInfoBd(rutUser, numberModulo, newPage);
    }
    this.router.navigate(["/curso"]);
  }
}
