<app-toolbar></app-toolbar>
<div class="container">
  <div class="pdf-container" *ngIf="isPaginaPdf()">
    <pdf-viewer
      [src]="newPdf"
      [render-text]="true"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="onPageRendered($event)"
      [show-all]="false"
      [page]="page"
      [original-size]="false"
    ></pdf-viewer>
  </div>

  <div *ngIf="!isPaginaPdf()">

    <div *ngIf="validShowPregunta(1)">
      <app-alternative-question
        [opciones]="opciones1"
        [correctAnswer]="correcta1"
        [choices]="choices1"
        [titulo]="titulo1"
        [descripcion]="descripcion1"
        [isRespondida]="validRespondida(1)"
        (sendRespuesta)="validarCuestionario($event,1)"
      ></app-alternative-question>
    </div>

    <div *ngIf="validShowPregunta(2)">
      <app-simple-question
      [titulo]="titulo2"
        [descripcion]="descripcion2"
        [opciones]="opciones2"
        [correctAnswer]="correcta2"
        [isRespondida]="validRespondida(2)"
        (sendRespuesta)="validarSimpleQuestion($event,2)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(3)">
      <app-true-false-question
        [titulo]="titulo3"
        [descripcion]="descripcion3"
        [questions]="preguntas3"
        [respuestas]="respuestas3"
        [isRespondida]="validRespondida(3)"
        (sendRespuesta)="validarCuestionario($event,3)"
      ></app-true-false-question>
    </div>

    <div *ngIf="validShowPregunta(4)">
      <app-simple-question
      [titulo]="titulo4"
        [descripcion]="descripcion4"
        [opciones]="opciones4"
        [correctAnswer]="correcta4"
        [isRespondida]="validRespondida(4)"
        (sendRespuesta)="validarSimpleQuestion($event,4)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(5)">
      <app-simple-question
      [titulo]="titulo5"
        [descripcion]="descripcion5"
        [opciones]="opciones5"
        [correctAnswer]="correcta5"
        [isRespondida]="validRespondida(5)"
        (sendRespuesta)="validarSimpleQuestion($event,5)"
      ></app-simple-question>
    </div>

    <div *ngIf="validShowPregunta(6)">
      <app-simple-question
      [titulo]="titulo6"
        [descripcion]="descripcion6"
        [opciones]="opciones6"
        [correctAnswer]="correcta6"
        [isRespondida]="validRespondida(6)"
        (sendRespuesta)="validarSimpleQuestion($event,6)"
      ></app-simple-question>
    </div>

    <div class="p-4 justify-center item-center" *ngIf="validShowPregunta(7)">
      <h1>🎉 ¡FELICIDADES HAZ FINALIZADO EL MODULO! 🎉</h1>
      <img class="w-full p-4" src="../../../../assets/images/fin-modulo.jpg" />
      <div class="w-100 px-4 py-6">
        <button
          class="btn bg-orange-500 btn-lg w-100 p-2 text-white text-lg font-bold"
          (click)="finModulo()"
        >
          Salir
        </button>
      </div>
    </div>
  </div>
</div>
<app-paginacion
  [page]="page"
  [totalPages]="totalPages"
  [cantPreg]="cantPreg"
  (prevPage)="prevPage()"
  (nextPage)="nextPage()"
></app-paginacion>
