<h1 class="text-2xl py-2" mat-dialog-title>Codigo Fin de Curso</h1>
<div
  mat-dialog-content
  class="d-flex flex-column justify-content-center align-items-center"
>
  Felicitaciones, <br> tu curso a terminado tu Código único es:
  <div class="flex text-center item-center h-full ">
    <div class="px-2 font-bold ">
        {{data}}
    </div>
    <div>
        <button mat-raised-button color="primary" (click)="copyText()">
            <span class="material-icons"> content_copy </span>
          </button>
    </div>
  </div>
</div>
