import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-zoom-dialog',
  templateUrl: './image-zoom-dialog.component.html',
  styleUrls: ['./image-zoom-dialog.component.css']
})
export class ImageZoomDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageUrl: string }) { }

  ngOnInit(): void {
    
  }

}
