import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PWAService } from 'src/app/services/pwa/pwa.service';
import Swal from 'sweetalert2';
import { CryptoservService } from 'src/app/services/cryposerv/cryptoserv.service';

@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.scss']
})
export class BienvenidaComponent implements OnInit {
  public user: any;
  public isIOS: any;
  constructor(
    private router: Router,
    private pwaservice: PWAService,
    private cryptoServ: CryptoservService
    ) {
      var tempInfoUser = JSON.parse(localStorage.getItem('usuario'));
      tempInfoUser.rut = this.cryptoServ.decrypted(tempInfoUser.rut);
      tempInfoUser.name = this.cryptoServ.decrypted(tempInfoUser.name);
      this.user = tempInfoUser;
      this.isIOS = this.pwaservice.isIOS;
    }

  ngOnInit(): void {
  }

  installAndroid(): void {
    this.pwaservice.triggerInstall().subscribe(pwaInstall => {
      if (pwaInstall) {
        this.router.navigate(['/']);
      } else {
        Swal.fire('Por favor instale la aplicación');
      }
    });
  }

  EntrarCurso(): void {
    this.router.navigate(['/']);
  }
}
