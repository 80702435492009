import { Component, OnInit, ChangeDetectorRef, Renderer2, AfterViewInit} from "@angular/core";
import Swal from "sweetalert2";
import { PdfViewerModule } from "ng2-pdf-viewer";
//Inicio Importa para variable de avanzar pregunta
import { Router } from "@angular/router";
import { TrackingService } from "src/app/services/tracking/tracking.service";
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
//Fin Importa para variable de avanzar pregunta
import { PDFDocumentProxy } from "pdfjs-dist";

@Component({
  selector: "app-modulo2",
  templateUrl: "./modulo2.component.html",
  styleUrls: ["./modulo2.component.scss"],
})
export class Modulo2Component implements OnInit, AfterViewInit {
  //Inicio Variable para avanzar de pagina
  numberModulo: number = 2;
  //Fin Variable para avanzar de pagina

  constructor(
    //Inicio declaracion de import para uno de avanzar pregunta
    private trackingService: TrackingService,
    private router: Router,
    private cryptoServ: CryptoservService,
    private renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef //Fin declaracion de import para uno de avanzar pregunta
  ) {}
  preguntas = [];
  respuesta = [];
  pathPdf = "../../../../assets/PDF/";

  newPdf = this.pathPdf + "modulo2_full.pdf";
  page = 1;
  totalPages: number = 99999;
  cantPreg = 6;

  //pregunta 1
  tituloCus = "alternativas";
  descripcionCus = `En la  diapositiva  anterior  encuentra las  Tablas de dosificación de Lavaloza y Cloro para programas Junaeb para que realice el cálculo de 355  bandejas Elija la alternativa correcta de dosificación  y tiempo`;
  inputHtml = `
    <div class="grid grid-cols-7 border-2 text-center">
          <div class="col-span-2 row-span-2 border-2 bg-red-600 flex items-center justify-center">
            <img
            class="h-20"
            src="../../../../../assets/images/junaeb_logo.png"
          />
          </div>
          <div class="col-span-2 border-2 bg-red-600 text-white">
            Paso 1: Lavado
          </div>
          <div class="col-span-3 border-2 bg-red-600 text-white">
            Paso 2: Sanitizado
          </div>
          <div class="border-2">
            Lavalozas (Cc)
          </div>
          <div class="border-2">
            Agua Caliente (Lt)
          </div>
          <div class="border-2">
            Cloro (Cc)
          </div>
          <div class="border-2">
            Agua Fria (Lt)
          </div>
          <div class="border-2">
            Tiempo (Segundos)            
          </div>
          <!-- a -->
          <div class="border-2">
            A)
          </div>
          <div class="border-2">
            355
          </div>
          <div class="border-2">
            319.5
          </div>
          <div class="border-2">
            64
          </div>
          <div class="border-2">
            142
          </div>
          <div class="border-2">
            89
          </div>
          <div class="border-2">
            20
          </div>
          <!-- b -->
          <div class="border-2">
            B)
          </div>
          <div class="border-2">
            355
          </div>
          <div class="border-2">
            425
          </div>
          <div class="border-2">
            86
          </div>
          <div class="border-2">
            192
          </div>
          <div class="border-2">
            120
          </div>
          <div class="border-2">
            30
          </div>
          <!-- c -->
          <div class="border-2">
            B)
          </div>
          <div class="border-2">
            355
          </div>
          <div class="border-2">
            320
          </div>
          <div class="border-2">
            71
          </div>
          <div class="border-2">
            144
          </div>
          <div class="border-2">
            90
          </div>
          <div class="border-2">
            20
          </div>
        </div>
    `;

  opcionesCus: string[] = [
    "A es correcta",
    "B es correcta",
    "A y B es correcta",
    "C es correcta",
  ];
  correctaCus = 0;

  //pregunta 1
  titulo1 = "EVALUACIÓN DEL MÓDULO";
  descripcion1 =
    "INSTRUCCIONES: <br> Existen 3 tipos de peligro que pueden contaminar a los alimentos Peligro Biológico, Peligro Químico, Peligro Físico <br> Identificar las alternativas corresponden a peligro biologico:";
  opciones1: string[] = [
    "Temperatura inadecuada en el transporte, preparación almacenamiento de los alimentos",
    "Practicas inadecuadas de higiene en la manipulación de alimentos",
    "Fragmentos de vidrio en una preparación",
    "Alimentos con residuo de pesticidas",
  ];
  choices1: string[] = [
    "solo a y b",
    "solo c y d",
    "todas anteriores"
  ];
  correcta1 = 0;

  //pregunta 2
  // titulo2 = "";
  // descripcion2 =
  //   "Identifique en que etapa(s) del flujo de procesos los microorganismos peligrosos podrían multiplicarse hasta el punto que constituyan un PELIGRO ALIMENTARIO";
  // opciones2: string[] = [
  //   "OPERACIONES PRELIMINARES <br> Carga bacteriana por incumplimiento de proceso de  sanitización de  vegetales",
  //   "RECEPCIÓN Y ALMACENAMIENTO <br> Proliferación de patógenos por aumento de la temperatura >5°C refrigeración",
  //   "OPERACIONES DEFINITIVAS <br> Por incumplimiento de temperatura de cocción mínimo 75°c",
  // ];
  // choices2: string[] = [
  //   "1 Y 3 ES CORRECTA",
  //   "1 Y 2 ES CORRECTA",
  //   "TODAS LAS ANTERIORES",
  // ];
  // correcta2 = 2;

  //pregunta 3
  titulo2 = "";
  descripcion2 =
    "¿Cómo podemos prevenir una Enfermedad de Transmisión Alimentaria?";
  opciones2: string[] = [
    "Lavándonos las manos",
    "Cocinar los alimentos a temperaturas por sobre los 75 °C",
    "Manteniendo una óptima cadena de frío de los alimentos",
    "Todas las alternativas son correctas",
  ];
  correcta2: string = "Todas las alternativas son correctas";

  //Pregunta 4
  titulo3 = "Identificar Peligro Biológico";
  descripcion3 =
    "Elija la respuesta correcta y responda V o F <br> De las causas de PELIGRO identifique a que etapa(s) del flujo de procesos corresponden  los peligros biológicos detallados a continuación  <br> PELIGRO BIOLOGICO POR PRESENCIA DE CARGA BACTERIANA EN :";
  preguntas3: any[] = [
    {
      text: "¿Podrían las materias primas contener microorganismos patógenos, toxinas, productos químicos u objetos físicos que dañen la salud de las personas? ",
      value: true,
    },
    {
      text: "La Temperatura adecuada para la reproducción de los Microorganismos  y la presencia de Oxigeno favorecen el crecimiento de Microorganismos ",
      value: true,
    },
    {
      text: "La humedad del producto, influye en  la sobrevivencia de patógenos (bacterias, parásitos y hongos)?",
      value: true,
    },
  ];

  //Pregunta numero 5
  titulo4: string = "";
  descripcion4: string =
    "¿Cual es el peligro de NO limpiar y sanitizar mesón de preparación de los alimentos entre uso y uso?";
  opciones4: string[] = [
    "Alimentos con sabores extraños",
    "Mala higiene personal",
    "Contaminación cruzada",
    "Abuso de tiempo y temperatura",
  ];
  correcta4: string = "Contaminación cruzada";

  //Pregunta numero 6
  titulo5: string = "";
  descripcion5: string =
    "¿Que patógeno está primariamente en la nariz y garganta de los seres humanos?";
  opciones5: string[] = [
    "Echerichia Coli",
    "Bacillus cereus",
    "Salmonella",
    "Staphylococcus aureus",
  ];
  correcta5: string = "Staphylococcus aureus";

  //Pregunta numero 7
  titulo6: string = "";
  descripcion6: string =
    "Que práctica puede reducir a niveles seguros la salmonella en los alimentos";
  opciones6: string[] = [
    "Almacenar los alimentos a una temperatura de -18°C",
    "Inspeccionar los alimentos enlatados para ver si están dañados.",
    "Tiempos de cocción de los alimentos a la temperatura correcta.",
    "Comprar la materia prima  a proveedores aprobados",
  ];
  correcta6: string =
    "Tiempos de cocción de los alimentos a la temperatura correcta.";

  ngOnInit(): void {
    var paginaLink = +window.location.href.split("#")[1];
    var forPagina = "pagina" + paginaLink;
    console.log("paginaLink: " + paginaLink);
    var NumPag: number = paginaLink;
    console.log("num pagina: " + NumPag);
    this.validarAvancePagina(NumPag);
    //document.getElementById(forPagina).hidden = false;
  }

  ngAfterViewInit() {
    const pdfViewer = document.querySelector('pdf-viewer');

    if (pdfViewer) {
      const canvasWrapper = pdfViewer.querySelector('.canvasWrapper');
      const textLayer = pdfViewer.querySelector('.textLayer');

      if (canvasWrapper) {
        this.renderer.setStyle(canvasWrapper, 'overflow', 'auto');
      }

      if (textLayer) {
        this.renderer.setStyle(textLayer, 'position', 'relative');
      }

      // Asegúrate de que el visor del PDF se centra verticalmente
      this.renderer.setStyle(pdfViewer, 'display', 'flex');
      this.renderer.setStyle(pdfViewer, 'flex-direction', 'column');
      this.renderer.setStyle(pdfViewer, 'justify-content', 'center');
      this.renderer.setStyle(pdfViewer, 'align-items', 'center');
    }
  }

  validarAvancePagina(pagina) {
    switch (pagina) {
      case 2:
        this.page = 4;
        break;
      case 3:
        this.page = 6;
        break;
      case 4:
        this.page = 8;
        break;
      case 5:
        this.page = 11;
        break;
      case 6:
        this.page = 15;
        break;
      case 7:
        this.page = 16;
        break;
      case 8:
        this.page = 17;
        break;
      case 9:
        this.page = 19;
        break;
      case 10:
        this.page = 26;
        break;
      case 11:
        this.page = 28;
        break;
    }
  }

  validarCuestionario(data: any, posicion: number) {
    // Aquí puedes manejar los datos como desees
    if (data) {
      const pregunta = this.numberModulo.toString() + "-" + posicion.toString();
      this.page++;
      localStorage.setItem(pregunta, "true");
      Swal.fire({
        icon: "success",
        title: "¡Super Bien!",
        text: "¡Tu respuesta esta correcta! 🥳",
        confirmButtonText: `¡Avanzar a la siguiente pregunta!`,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "¡No es correcta tu respuesta! 😔  Favor seguir intentando",
        confirmButtonText: `Volver a responder`,
      });
    }
  }

  validarSimpleQuestion(data: any, posicion: number) {
    if (data) {
      //localStorage.setItem("m1-p1", "true");
      const pregunta = this.numberModulo.toString() + "-" + posicion.toString();
      localStorage.setItem(pregunta, "true");
      this.page++;
      this.validSavePag();
      Swal.fire({
        icon: "success",
        title: "¡Super Bien!",
        text: "¡Tu respuesta esta correcta! 🥳",
        confirmButtonText: `¡Avanzar a la siguiente pregunta!`,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "¡No es correcta tu respuesta! 😔  Favor seguir intentando",
        confirmButtonText: `Volver a responder`,
      });
    }
  }

  validShowPregunta(pos) {
    if (this.page - this.totalPages == pos) {
      return true;
    }
    return false;
  }

  validRespondida(number) {
    const pregunta = this.numberModulo.toString() + "-" + number.toString();
    //console.log(pregunta);
    const infoEncryp = localStorage.getItem(pregunta);
    //console.log(infoEncryp);
    if (infoEncryp == null || infoEncryp == undefined || infoEncryp == "") {
      return false;
    }
    return true;
  }

  async updateInfoBd(rut: string, modulo: number, pagina: number) {
    await this.trackingService.setAllTracking(rut, modulo, pagina);
  }

  //sath revisar
  validSavePag() {
    var temp = localStorage.getItem("modulo");
    var lastModule = this.cryptoServ.decrypted(temp);
    // var lastModule = localStorage.getItem('modulo');
    var temp = localStorage.getItem("pagina");
    var lastPagina = this.cryptoServ.decrypted(temp);
    // var lastPagina = localStorage.getItem('pagina');
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    // var rutUser = localStorage.getItem('rut');
    console.log(lastModule)
    console.log(lastPagina)
    console.log(rutUser)
    console.log(this.numberModulo)
    console.log('pagina:'+this.page)
    if (
      this.numberModulo == parseInt(lastModule) &&
      this.page >= parseInt(lastPagina)
    ) {
      var encrypt = this.cryptoServ.encrypted(this.page);
      localStorage.setItem("pagina", encrypt);
      // localStorage.setItem('pagina', JSON.stringify(this.page));
      console.log("Antes enviar")
      this.updateInfoBd(rutUser, this.numberModulo, this.page);
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this._changeDetectorRef.markForCheck();
  }

  // Método que se llama cuando se renderiza una página
  onPageRendered(event: any) {
    if (this.page === this.totalPages) {
      console.log("Última página del PDF mostrada");
      // Aquí puedes ejecutar cualquier lógica adicional que necesites
    }
  }

  // Método para ir a la página anterior
  prevPage() {
    if (this.page > 1) {
      this.page--;
    }
  }

  nextPage() {
    this.page++;
    // var encrypt = this.cryptoServ.encrypted(this.page);
    // localStorage.setItem("pagina", encrypt);
    //this.validSavePag();
  }

  isPaginaPdf() {
    if (this.page <= this.totalPages) {
      return true;
    }
    return false;
  }

  finModulo() {
    const tempModulo = localStorage.getItem("modulo");
    const lastModule = this.cryptoServ.decrypted(tempModulo);
    const tempRut = localStorage.getItem("rut");
    const rutUser = this.cryptoServ.decrypted(tempRut);
    if (parseInt(lastModule) < this.numberModulo + 1) {
      const newPage = 0;
      const numberModulo = this.numberModulo + 1;
      const encryptModulo = this.cryptoServ.encrypted(numberModulo);
      localStorage.setItem("modulo", encryptModulo);
      const encryptPagina = this.cryptoServ.encrypted(0);
      localStorage.setItem("pagina", encryptPagina);
      this.updateInfoBd(rutUser, numberModulo, newPage);
    }
    this.router.navigate(["/curso"]);
  }
}
