import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, FormControl } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-true-false-question",
  templateUrl: "./trueFalseQuestion.component.html",
  styleUrls: ["./trueFalseQuestion.component.scss"],
})
export class TrueFalseQuestionComponent implements OnInit {
  @Input() titulo: string = "";
  @Input() descripcion: string = "";
  @Input() questions: any[] = [];
  @Input() isRespondida: boolean;
  @Output() sendRespuesta: EventEmitter<any> = new EventEmitter<any>();

  questionsForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.questionsForm = this.fb.group({
      answers: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.addQuestions();
    if (this.isRespondida) {
      this.questionsForm = this.fb.group({
        answers: this.fb.array([]),
      });
      for (let i = 0; i < this.questions.length; i++) {
        this.questions[i].respuesta = this.questions[i].value;
      }
    }
  }

  private addQuestions() {
    // this.questions.forEach(question => {
    //   this.answers.push(new FormControl(question.answer));
    // });
  }

  get answers(): FormArray {
    return this.questionsForm.get("answers") as FormArray;
  }

  moveSelect(opc, pregunta, pos) {
    let aux = pregunta;
    aux.respuesta = opc;
    this.questions[pos] = aux;
  }

  onSubmit() {
    console.log(this.questionsForm.value);
    for (let i = 0; i < this.questions.length; i++) {
      let auxInfo = this.questions[i];
      if (auxInfo.respuesta != auxInfo.value) {
        this.sendRespuesta.emit(false);
        return;
      }
    }
    this.sendRespuesta.emit(true);
  }

  opcSeleccionada(pos) {
    console.log(pos);
    // console.log(this.correctAnswer);
    // if(pos == this.correctAnswer){
    //   this.sendRespuesta.emit(true);
    // } else {
    //   this.sendRespuesta.emit(false);
    // }
  }
}
