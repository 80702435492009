import { Component, OnInit, Inject } from '@angular/core';
import { ExportService } from 'src/app/services/export/export.service';
import { TrackingService } from 'src/app/services/tracking/tracking.service';
import { CryptoservService } from 'src/app/services/cryposerv/cryptoserv.service';
import { md5 } from './md5';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RutService } from 'rut-chileno';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-curso',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  
  file: File;
  arrayBuffer: any;
  filelist: any;
  cantidadLeido = 0;
  arraylist: any;


  preguntaIncompleta = [];
  opcionFinal;
  rutPersona = "";
  nombrePersona = "";

  constructor(
    private exportService: ExportService,
    private trackingService: TrackingService,
    private cryptoServ: CryptoservService,
    public dialog: MatDialog,
    private rutService: RutService,
    private bdFirebase: AngularFirestore,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const tempRut = localStorage.getItem('rut');
    this.rutPersona = this.cryptoServ.decrypted(tempRut);
    this.rutPersona = this.rutService.rutFormat(this.rutPersona);
    const usuario = localStorage.getItem('usuario');
    const aux = JSON.parse(usuario);
    this.nombrePersona = this.cryptoServ.decrypted(aux.name);
    let perfil = this.cryptoServ.decrypted(aux.perfil);
    console.log(perfil);
    if(perfil != "0"){
      alert("No es un administrador");
      this.router.navigate(['/bienvenida']);
    }
  }

  async cargarUsuarios() {
    console.log("Se inicia la carga de usuarios");
    await this.deleteCollection('usuario');
    console.log("Se comienzan a cargar los usuarios");
    await this.AgregarUserBd();
  }


  async deleteCollection(path: string) {
    console.log("Se comienza a eliminar los componentes");
    await this.bdFirebase.collection(path).ref.get().then(function (doc): void {
      console.log("Se validara documento");
      console.log("Cantidad de registros");
      console.log(doc.size);
      for (let i = 0; i < doc.size; i++) {
        doc.docs[i].ref.delete();
      }
    });
    console.log("Se Elimino con exito los registros");
  }

  addfile(event) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      this.arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.cantidadLeido = this.arraylist.length;
    }
  }

  async AgregarUserBd() {
    try {
      console.log("Se cargan los usuarios");
      await this.bdFirebase.collection('usuario').doc("175797742").set({
        Nombre: "Sebastian Teran",
        Perfil: 0,
        FecCreacion: new Date()
      }, { merge: true });
      await this.bdFirebase.collection('usuario').doc("187259290").set({
        Nombre: "Gustavo Plaza",
        Perfil: 0,
        FecCreacion: new Date()
      }, { merge: true });
      console.log("Se agrego usuario administrador");
      console.log("Se inicia el recorrido de los usuarios");
      for (let i = 0; i < this.arraylist.length; i++) {
        let rut = this.arraylist[i].Rut;
        let nombre = this.arraylist[i].Nombre;
        let supervisor = this.arraylist[i].Supervisor;
        rut = this.rutService.rutClean(rut);
        console.log("Se carga rut: " + rut);
        let perfil = 2;
        if (supervisor == "Si") {
          perfil = 1;
        }
        await this.bdFirebase.collection('usuario').doc(rut).set({
          Nombre: nombre,
          Perfil: perfil,
          FecCreacion: new Date()
        }, { merge: true });
      }
      console.log("Fin del recorrido de los usuarios");
    } catch (error) {
      console.log('cai en el segundo error:' + error);
    }
  }

}
