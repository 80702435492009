import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-order-question",
  templateUrl: "./orderQuestion.component.html",
  styleUrls: ["./orderQuestion.component.scss"],
})
export class OrderQuestionComponent {
  @Input() opciones: string[];
  @Input() ordenCorrecto: string[];
  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() isRespondida: boolean;
  @Output() sendRespuesta: EventEmitter<any> = new EventEmitter<any>();

  orderForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.orderForm = this.fb.group({
      options: [this.opciones],
    });
    if(this.isRespondida){
      this.orderForm = this.fb.group({
        options: [this.ordenCorrecto],
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if(this.isRespondida){
return;
    }
    moveItemInArray(this.opciones, event.previousIndex, event.currentIndex);
  }

  onSubmit() {
    if (JSON.stringify(this.opciones) === JSON.stringify(this.ordenCorrecto)) {
      this.sendRespuesta.emit(true);
    } else {
      this.sendRespuesta.emit(false);
    }
  }
}
