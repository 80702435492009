import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingService } from 'src/app/services/tracking/tracking.service';
import { PWAService } from 'src/app/services/pwa/pwa.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  title: string;
  public isIOS: any;

  constructor(
    private router: Router,
    private trackingService: TrackingService,
    private pwaservice: PWAService,
  ) {
    this.title = 'Capacitación Manquehue';
  }

  ngOnInit(): void {
    this.isIOS = this.pwaservice.isIOS;
  }

  toCurso(): void {
    var tracedPage = window.location.href.split('/');
    var extension = tracedPage[tracedPage.length - 1];
    if (extension == "curso") {
      window.location.reload();
    } else {
      this.router.navigate(['/curso']);
    }
  }

  isConnect(): any {
    return this.trackingService.getLocaleStatusOnline();
  }

  isNotInstaller(){
    let info = this.pwaservice.isPWAInstalledOnAndroid();
    return  !info;
  }

  installAndroid(): void {
    this.pwaservice.triggerInstall().subscribe(pwaInstall => {
      if (pwaInstall) {
        Swal.fire('Aplicación instalada con exito');
      } else {
        Swal.fire('Se cancelo instalación');
      }
    });
  }

  cerrarSesion(): any {
    if(this.isConnect()){
      Swal.fire({
        icon:"warning",
        title: "Confirmación",
        text: "¿Está seguro de que desea cerrar sesión? Existen cambios que no se han guardado en internet.",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          this.router.navigate(['/']);
        } 
      });
    } else {
      Swal.fire({
        icon:"warning",
        title: "Confirmación",
        text: "¿Está seguro de que desea cerrar sesión? Existen cambios que no se han guardado en internet.",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Saved!", "", "success");
        } 
      });
    }
  }

}
