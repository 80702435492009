import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BienvenidaComponent } from './components/bienvenida/bienvenida.component';
import { CursoComponent } from './components/curso/curso.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ModuloAutoInstruccionComponent } from './components/modulos/modulo-auto-instruccion/modulo-auto-instruccion.component';
import { Modulo1Component } from './components/modulos/modulo1/modulo1.component';
import { Modulo2Component } from './components/modulos/modulo2/modulo2.component';
import { Modulo3Component } from './components/modulos/modulo3/modulo3.component';
import { Modulo4Component } from "./components/modulos/modulo4/modulo4.component";
import { BorrarInfoComponent } from './components/borrarInfo/borrarInfo.component';
import { AdminComponent } from './components/admin/admin.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { Modulo3JardinesComponent } from './components/modulos/modulo3jardines/modulo3-jardines.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'bienvenida',
    pathMatch: 'full',
    component: BienvenidaComponent,
    // redirectTo: 'curso'
    // canActivate: [AuthGuard]
  },
  {
    path: 'curso',
    pathMatch: 'full',
    component: CursoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo/MANUAL-DE-AUTO-INSTRUCCION',
    pathMatch: 'full',
    component: ModuloAutoInstruccionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo/Normas-Conceptos',
    pathMatch: 'full',
    component: Modulo1Component,
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo/Peligros',
    pathMatch: 'full',
    component: Modulo2Component,
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo/BPM',
    pathMatch: 'full',
    component: Modulo3Component,
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo/BPMJARDINES',
    pathMatch: 'full',
    component: Modulo3JardinesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'modulo/Tecnicas',
    pathMatch: 'full',
    component: Modulo4Component,
    canActivate: [AuthGuard]
  },
  {
    path: '21232f297a57a5a743894a0e4a801fc3',
    pathMatch: 'full',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'limpiarCache',
    component: BorrarInfoComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
